import React,{useRef} from 'react';
import Slider from "react-slick";
import ProductImage from '../common/ProductImage';
import useInViewport from '../../lib/hooks/useInViewport';
import HomeProductNewsImage from "./HomeProductNewsImage";
import { checkProductType } from '../../lib/utils/checkProductType';

const HomeProductNews = (props) => {  
    const ref = useRef();
    const inViewport = useInViewport(ref, '0px');
    const homeProductNews  = props?.homeProductNews;
    const slider_setting_homeproduct = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 8,
      slidesToScroll: 8,
      rows: 1,
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 6,
          }
        },
        {
          breakpoint: 1140,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            rows: 2
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            rows: 3
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    };
    return(
        <section className="regular image_slider_homeproducts mb-50">
          <Slider {...slider_setting_homeproduct}>
              {homeProductNews?.map((val, i) => (
                <div key={`productos${i}`}>
                    <HomeProductNewsImage imagesrc={val?.image} slug={`/${checkProductType(val.type)}/${val.slug}`}/>
                    {props?.page !== 'home' && val.title && <p>{val.title}</p>}
                </div>
              ))}
          </Slider>
        </section>
    )
}

export default HomeProductNews;
