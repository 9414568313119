import React,{useState, useRef, useEffect} from 'react';
import Link from "next/link";
import useInViewport from '../../lib/hooks/useInViewport';
import Image from "next/image";
import dummy_image from  '../../public/assets/images/dummyimage.png';

const HomeHeaderImage = ({imagesrc, href, zoom}) => {
    
    const ref = useRef();
    const inViewport = useInViewport(ref, '0px');
    const [imageNameSRC, setImageNameSRC] =  useState(process.env.NEXT_PUBLIC_IMAGE_PATH + imagesrc)   

    useEffect(()=>{
        setImageNameSRC(process.env.NEXT_PUBLIC_IMAGE_PATH + imagesrc)
    },[imagesrc])

    return(

        <Link href={href ? href : '/'} passHref={true}>
            <div className={`header-zoom-in ${inViewport && zoom? 'animation' : ''}`} ref={ref}>
                {imageNameSRC && <Image  src={imageNameSRC} alt="" className="backgroundimg" layout="fill" priority={true} onError={() => {
                    setImageNameSRC(dummy_image);}}
                ></Image>}
            </div>
        </Link>

    )
}

export default HomeHeaderImage;
