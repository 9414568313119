import Image from 'next/image';
import Link from 'next/link';
import React,{useState, useEffect} from 'react';
import Slider from "react-slick";
import GuiasLecturaSliderImage from "./GuiasLecturaSliderImage";

const GuiasLecturaSlider = ({bannersGuias,resolution}) => {
    const [widthHeight, setwdidthHeight] = useState([768, 199]);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);
        setWidth(window.innerWidth);

        return () => window.removeEventListener('resize', handleResize);

      }, []);

    useEffect(() => {
        if(width < 768) {
            setwdidthHeight([320, 240]);
        }else{
            setwdidthHeight([768, 199]);
        }
    }, [width]);

    const slider_guias = {dots: true, infinite: true,  autoplay: true, autoplaySpeed: 5000}

    const showImage = (banner)=> {return resolution>767 || !banner?.imageMobile ? banner?.image : banner?.imageMobile}

    return(
        <section className="regular image_slider_home_letura mb-50 container-fluid mt-30">
            <Slider {...slider_guias}>
                {bannersGuias?.map((banner,i) => {
                    return (<a key={`guias${i}`} rel="noreferrer"  href={banner.link}  target={banner.newWindow == 1 ? '_blank' : '_self'}>
                        <div className="pos-slider banners">                                                            
                            {resolution && <GuiasLecturaSliderImage zoom={banner.zoom} imagesrc={showImage(banner)} width={widthHeight[0]} height={widthHeight[1]}/>}
                            <p className="reading-guide-text">GUÍAS DE LECTURA</p>
                                {banner?.textColor && <div style={{color:`${banner?.textColor}`}} className="bannerText" dangerouslySetInnerHTML={{__html:banner?.text}}></div>}
                                {!banner?.textColor && <div  className="bannerText" dangerouslySetInnerHTML={{__html:banner?.text}}></div>}
                        </div>
                    </a>);
                })}
            </Slider>
        </section>
    )
}

export default GuiasLecturaSlider;
