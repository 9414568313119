
import React, {useEffect, useState, useRef} from 'react';
import Slider from "react-slick";
import Link from 'next/link';
import HomeComunicadosImage from "./HomeComunicadosImage";
import ContentImage from '../common/ContentImage.jsx';

const HomeCatalogoSection = (props) => {

    const homePublishinglines = props?.publishingLines?.data;
    const homeReleases =props?.homeReleases?.data;


    // useEffect(() => {
    //     _fetchPublishingline();
    // }, []);

    // const _fetchPublishingline = async () => {
    //     let publishingLines = await getHomePublishingLines();
    //     if(publishingLines.responseCode == '201') {
    //         sethomePublishinglines(publishingLines?.data);
    //     }
    //     let homeReleases = await getHomeReleases();
    //     if(homeReleases.responseCode == '201') {
    //         sethomeReleases(homeReleases?.data);
    //     }
    // }

    return (
        <div className="box3 pt-20">
            <div className="container main-container">
            <div className="row">
                <div className="col-sm-8 padmar">
                <div className="box--wrap marg">
                    <h6 className="novedades">CATÁLOGO</h6>
                    <h2 className="A-la-venta-esta-sema ">Explora nuestro catálogo</h2>
                    <div className="line4"></div>
                </div>
                <div className="catalogo">
                    {homePublishinglines?.map((val,i) => (
                        <li key={`publishingLine${i}`} className="eccPublishingLine">
                            <Link href={`/sellos/${val.slug}`} passHref={true}>
                                <div className="wrapper">
                                    {/*<Image src={process.env.NEXT_PUBLIC_PATH + val?.image} alt="" layout="fill" loading="eager"></Image>
                                    <img src={process.env.NEXT_PUBLIC_PATH + val?.image} alt=""></img>*/}
                                    <ContentImage imageName={val?.image} isImageComponent={false}/>
                                    <img className="publishLineHover" src={process.env.NEXT_PUBLIC_PATH + val?.homeHover} alt=""></img>
                                    {<label><Link href={`/sellos/${val.slug}`} passHref={true}>{val.publishingLine}</Link></label>}
                                </div>
                            </Link>
                        </li>
                    ))}
                </div>
                </div>
                <div className="col-sm-4 padmar">
                <div className="box--wrap marg">
                    <h6 className="novedades">NOVEDADES</h6>
                    <h2 className="A-la-venta-esta-sema ">Comunicados</h2>
                    <div className="line4"></div>
                    <section className="regular image_slider_homereleases">
                    <Slider>
                        {homeReleases?.map((val,i) => (
                            <div  key={`HomeRel${i}`} className="image_slider_homereleases_content">
                                <Link passHref={true} href={`/contenidos/${val.slug}`}>
                                    <div className="comunicados-wrapper">
                                      <div className="img-wrapper">
                                          {/*<Image src={process.env.NEXT_PUBLIC_IMAGE_PATH+`${val.image}`} alt="" layout="fill" ></Image>*/}
                                          <HomeComunicadosImage imageName={val?.image}/>
                                      </div>
                                      <h3>{val.title}</h3>
                                    </div>
                                </Link>
                                <div dangerouslySetInnerHTML={{ __html:val.shortContent}}></div>
                            </div>
                        ))}
                    </Slider>
                    </section>
                </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default HomeCatalogoSection;
