import React, { useEffect, useState } from "react";
import { useRouter } from 'next/router'

import Header from '../components/Layouts/Header.jsx';
import HomeFooter from '../components/Layouts/HomeFooter.jsx';
import EventModal from '../components/Modals/eventmodal.jsx';
import HomeCatalogoSection from '../components/Home/homecatalogosection.jsx';
import HomeProductNews from '../components/Home/homeproductnews.jsx';
import BannerComponent from '../components/Home/bannerComponent.jsx';
import HomeNoticias from '../components/Home/homeNoticiasComponent.jsx';
import HomeRevistaComponent from '../components/Home/homeRevistaComponent.jsx';
import HomeAgendaComponent from '../components/Home/homeagendaComponent.jsx';
import HomeHeaderImage from '../components/Home/HomeHeaderImage.jsx';
import SubscribeNewsLetter from '../components/common/subscribenewsletter.jsx';
import HeaderTag from '../components/meta.jsx';

import { getHomescheduledetails } from '../lib/api/schedule.jsx';
import { getHomeData, gethomeProductNews, getHomeNews, getHomePublishingLines, getHomeReleases, getHomeEccMagzine, getHomeSchedule } from "../lib/api/home.jsx";

import InitializePage from '../lib/server/page'

/*deploy*/
/*deploy2*/

const Home = (props) => {  
    const {webTexts} = props;

    const router = useRouter()
    const { preview } = router.query;

    const [resolution, setresolution] = useState(null);
    const [homebanners, setHomeBanners] = useState(props?.homebanner?.data);
    const [isModalVisible, setisModalVisible] = useState(false);
    const [modalContent, setmodalContent] = useState([]); 
    

    const returndata = (positionId, type) => {
        let resp = homebanners?.filter(x => x?.positionId == positionId);        
        if (!resp) resp = [];
        return resp;
    }

    const handleModal = async (val) => {
        const details = await getHomescheduledetails(val.id);
        if(details.responseCode == '201') {
            setisModalVisible(!isModalVisible);
            setmodalContent(details.data);
        }
    }

    useEffect(()=>{        
        setresolution(window.innerWidth);

        const handlePreview = async ()=>{
            if (preview && preview !== undefined && preview !== "") {            
                const obj =  'previewToken=' + preview;
                const getHomeBannerResponse =  await getHomeData(obj);
                const newBanners = getHomeBannerResponse?.data
                
                const mergedBanners = homebanners.map(obj => newBanners.find(o => o.positionId === obj.positionId) || obj);

                setHomeBanners(mergedBanners);
            }
        }
        handlePreview();
    },[preview])
    

    const getImage = ()=>{
        return resolution>767 ? returndata(1)[0]?.image : returndata(1)[0]?.imageMobile ? returndata(1)[0]?.imageMobile : returndata(1)[0]?.image
    }
    return (
    <>
        <HeaderTag title={`Ecc Ediciones ${process.env.NEXT_PUBLIC_ENVIRONMENT}`} description="Página web oficial de ECC Ediciones, la editorial de DC  Comics en castellano. Superhéroes como Batman, Superman, Green Lantern o Flash residen aquí. Conecta con la lectura" keywords="ecc, ediciones, dc, comics, batman, superman" />
        <div className="bootstrap-iso" >            
            <header className="header1 mb-50">
                {resolution && <HomeHeaderImage zoom={returndata(1)[0]?.zoom} href={returndata(1)[0]?.link} imagesrc={getImage()}/>} 
                <Header bannersdata={homebanners} textColor={returndata(1)[0]?.textColor} textData={returndata(1)[0]?.text} page={'home'} menuSections={props.menu} menu={returndata(1)[0]?.headerStyle === 1 ? 'black' : 'white'}/>
            </header>           
        </div>
        <div className="container box--wrap heading-container pt-20">
            <h6 className="novedades">NOVEDADES</h6>
            <h2 className="A-la-venta-esta-sema ">A la venta esta semana</h2>
            <div className="line4"></div>
        </div>
        <HomeProductNews page={'home'} {...props}/>
        <HomeCatalogoSection {...props}/>
        <BannerComponent getdata={returndata} type={1} homebanners={homebanners}  resolution={resolution}/>
        <HomeNoticias {...props}/>
        <BannerComponent getdata={returndata} type={2} homebanners={homebanners}  resolution={resolution}/>
        <div className="container box--wrap heading-container lectura">
            <h2 className="A-la-venta-esta-sema">Espacios</h2>
            <div className="line4"></div>
        </div>
        <BannerComponent getdata={returndata} type={3} homebanners={homebanners}  resolution={resolution}/>
        <div className="container revista main-container">
            <div className="row">
                <HomeRevistaComponent {...props}/>
                <HomeAgendaComponent handleModal={handleModal} {...props}/>
            </div>
        </div>
        <BannerComponent resolution={resolution} getdata={returndata} type={4} homebanners={homebanners}/>
        <SubscribeNewsLetter webTexts={webTexts}/>
        <HomeFooter />
        <EventModal
            isModalVisible={isModalVisible}
            handleCancel={() => setisModalVisible(!isModalVisible)}
            modalContent={modalContent}/>
    </>
  )
};

async function loadData(context){            
    const homebanner =  await getHomeData();
    const homeProductNews = await gethomeProductNews();
    const publishingLines = await getHomePublishingLines();
    const homeReleases = await getHomeReleases();
    const homeNews = await getHomeNews();
    const magzine = await getHomeEccMagzine();
    const schedule = await getHomeSchedule(1);
    
    if(homebanner.responseCode == '404') {
      return {
        notFound: true,
      }
    }

    if(homeProductNews.responseCode == '404') {
      return {
        notFound: true,
      }
    }

    if(publishingLines.responseCode == '404') {
      return {
        notFound: true,
      }
    }

    if(homeReleases.responseCode == '404') {
      return {
        notFound: true,
      }
    }

    if(homeNews.responseCode == '404') {
      return {
        notFound: true,
      }
    }

    if(magzine.responseCode == '404') {
      return {
        notFound: true,
      }
    }

    if(schedule.responseCode == '404') {
      return {
        notFound: true,
      }
    }

    return {homebanner, homeProductNews:homeProductNews?.data, publishingLines, homeReleases, homeNews, magzine, schedule}

}

export async function getStaticProps(res, context) { 
    
    return InitializePage(context ,loadData,true)
}

export default Home;
