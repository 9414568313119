import React,{useRef} from 'react';
import useInViewport from '../../lib/hooks/useInViewport';
import Image from "next/image";
import ProductImage from '../common/ProductImage';

const HomeProductNewsImage = ({imagesrc, slug}) => {

    return(
      <div className="img-wrapper scale-in">
        <ProductImage slug={slug} home={'true'} imageName={imagesrc} layout="responsive" sizes="100vw" width={174} height={269}></ProductImage>
      </div>
    )
}

export default HomeProductNewsImage;
